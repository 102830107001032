import { defineStore, storeToRefs } from 'pinia'
import { useSignUpExperiments } from '@shared/composables/experiment.js'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { computed, ref, toValue, watch } from 'vue'
import { useStepper } from '@vueuse/core'
import { useLogger } from 'vue-logger-plugin'
import { useStyleProfileStore } from '@/stores/styleProfile.js'
import useScreenSize from '@shared/composables/screenSize.js'
import { useExperimentsStore } from '@shared/stores/experiments.js'
import { useStyleQuizUrl } from '@/homepage/composables/styleQuizUrl.js'

// Temporary composable for Sign Up Revamp phase 1.
function useLegacyStyleQuizNav () {
  const route = useRoute()
  const router = useRouter()
  const store = useStore()

  const currentRoute = computed(() => store.getters['styleQuizNav/getCurrentStyleQuizRoute'](route))
  const nextRoute = computed(() => store.getters['styleQuizNav/getNextStyleQuizRouteForRouter'](route))

  async function next () {
    await router.push({ ...nextRoute.value, query: route.query })
  }

  return {
    currentRoute,
    nextRoute,
    next
  }
}

export const SignUpMode = {
  Default: 'default',
  PersonalizeLater: 'personalize-later',
  StyleQuiz: 'style-quiz'
  // TODO: event sign-up?
}
Object.freeze(SignUpMode)

export const useSignUpNavigationStore = defineStore('signUpNavigation', () => {
  const styleQuizNav = useLegacyStyleQuizNav()
  const store = useStore()
  const logger = useLogger()
  const { isMobile, isTabletOrSmaller } = useScreenSize()

  const mode = ref(SignUpMode.Default)

  // Style Game
  const ratingsMap = computed(() => store.state.closet.ratingsMap) // TODO: need to fetch this?
  const ratedStyleCount = computed(() =>
    Object.values(ratingsMap.value).filter(rating => rating !== 0).length
  )
  const styleGameProgress = computed(() => {
    const totalSteps = 20 // TODO: computed from /api/stylecolors/style_quiz/ result size
    return ({
      step: Math.min(ratedStyleCount.value + 1, totalSteps),
      totalSteps
    })
  })
  const styleGameScreenProgress = computed(() =>
    isMobile.value
      ? styleGameProgress.value
      : styleQuizProgress.value
  )
  const canSkipStyleGame = computed(() => ratedStyleCount.value >= 10)
  const isStyleGameComplete = computed(() => canSkipStyleGame.value)

  // Style Quiz
  const styleQuizScreens = [
    'style-game',
    'hard-nos',
    'sizing',
    'clothes-sizing',
    'events',
    'reasons'
  ]
  Object.freeze(styleQuizScreens)
  const styleQuizProgress = computed(() => ({
    title: 'Style Quiz',
    ...getProgressForSection(styleQuizScreens)
  }))
  const styleProfile = useStyleProfileStore()
  const {
    isSizingComplete,
    isClothesSizingComplete,
    isAvoidsComplete,
    isSignUpEventsComplete,
    isSignUpReasonsComplete,
    isPlanSurveyComplete
  } = storeToRefs(styleProfile)
  const isStyleQuizComplete = computed(() =>
    isStyleGameComplete.value &&
    isSizingComplete.value &&
    isCheckoutComplete.value &&
    isSignUpReasonsComplete.value
  )
  const firstIncompleteStyleQuizScreen = computed(() =>
    getFirstScreenMatching(styleQuizScreens, screen => !isScreenComplete(screen))?.name ?? null
  )

  // Membership
  const membershipScreens = [
    'plan-choice-survey',
    'plan-choice',
    'checkout'
  ]
  Object.freeze(membershipScreens)
  const membershipProgress = computed(() => ({
    title: 'Membership',
    ...getProgressForSection(membershipScreens)
  }))
  const isPlanChoiceComplete = computed(() => store.state.subscribe.membershipPlanSelectedId !== null)
  const isCheckoutComplete = computed(() => store.state.client.membershipStatus === 'active')
  const isMembershipComplete = computed(() => isCheckoutComplete.value)

  const screens = {
    onboarding: {
      routeName: 'sign-up-onboarding',
      footer: {
        show: isTabletOrSmaller,
        buttonText: 'Get Smart Picks Now'
      }
    },
    'create-account': {
      routeName: 'sign-up-create-account'
    },
    // Style Quiz
    'style-game': {
      routeName: 'sign-up-style-game',
      progress: styleGameScreenProgress,
      canSkip: canSkipStyleGame,
      isComplete: isStyleGameComplete
    },
    'hard-nos': {
      routeName: 'sign-up-hard-nos',
      progress: styleQuizProgress,
      canSkip: true,
      footer: {
        show: isAvoidsComplete
      }
    },
    sizing: {
      routeName: 'sign-up-sizing',
      progress: styleQuizProgress,
      isComplete: isSizingComplete,
      footer: {
        show: isSizingComplete
      }
    },
    'clothes-sizing': {
      routeName: 'sign-up-clothes-sizing',
      progress: styleQuizProgress,
      isComplete: isClothesSizingComplete,
      footer: {
        show: isClothesSizingComplete
      }
    },
    events: {
      routeName: 'sign-up-events',
      progress: styleQuizProgress,
      canSkip: true,
      footer: {
        show: isSignUpEventsComplete
      }
    },
    reasons: {
      routeName: 'sign-up-reasons',
      progress: styleQuizProgress,
      isComplete: isSignUpReasonsComplete,
      footer: {
        show: isSignUpReasonsComplete
      }
    },
    // Membership
    'plan-choice-survey': {
      routeName: 'sign-up-plan-choice-survey',
      progress: membershipProgress,
      isComplete: isPlanSurveyComplete,
      footer: {
        show: isPlanSurveyComplete,
        buttonText: 'Find My Plan'
      }
    },
    'plan-choice': {
      routeName: 'sign-up-plan-choice',
      progress: membershipProgress,
      isComplete: isPlanChoiceComplete,
      footer: {
        show: isPlanChoiceComplete
      }
    },
    checkout: {
      routeName: 'sign-up-checkout',
      progress: membershipProgress,
      isComplete: isCheckoutComplete
    },
    'next-steps': {
      routeName: 'sign-up-next-steps',
      footer: {
        show: true,
        buttonText: 'Let\'s Go!'
      }
    }
  }
  Object.keys(screens).forEach(name => {
    const screen = screens[name]
    screen.name = name
    screen.url = name === 'onboarding' ? '/sign-up/' : `/sign-up/${name}/`
  })
  Object.freeze(screens)

  const activeScreens = computed(() => {
    switch (mode.value) {
      case SignUpMode.PersonalizeLater:
        return excludeScreensBetween('create-account', 'plan-choice-survey')
      case SignUpMode.StyleQuiz:
        return screenNames.slice(screenNames.indexOf('style-game')).reduce((acc, name) => {
          acc[name] = screens[name]
          return acc
        }, {})
      case SignUpMode.Default:
      default:
        return screens
    }
  })

  const screenNames = Object.keys(screens)
  Object.freeze(screenNames)

  const stepper = useStepper(activeScreens)

  const { signUpPhase3 } = useSignUpExperiments()
  const { styleQuizUrl } = useStyleQuizUrl()
  const startScreen = computed(() => stepper.steps.value[stepper.stepNames.value[0]])
  function getStartUrl (referralCampaign = null) {
    const url = new URL(signUpPhase3.value ? startScreen.value.url : styleQuizUrl.value, window.location.origin)
    url.search = referralQueryParams.value
      ? new URLSearchParams(referralQueryParams.value).toString()
      : ''
    const planId = referralCampaign?.defaultPlan?.id ?? window.armoire?.plans?.unlimited?.id
    if (planId) {
      url.searchParams.append('plan', planId)
    }
    return `${url.pathname}${url.search}`
  }
  const startUrl = computed(() => getStartUrl())

  const currentScreen = computed(() => stepper.current.value)
  const currentScreenRouterLink = computed(() => ({
    name: currentScreen.value.routeName,
    ...(routeQuery.value ?? {})
  }))
  const nextScreen = computed(() => screens[stepper.next.value])
  const nextScreenRouterLink = computed(() => ({
    name: nextScreen.value?.routeName ?? 'closet-sections',
    ...(routeQuery.value ?? {})
  }))
  const progress = computed(() => currentScreen.value.progress?.value ?? null)

  const route = useRoute()
  const referralQueryParams = computed(() => {
    const keys = ['r', 's']
    const params = keys.reduce((acc, key) => {
      if (route.query[key]) {
        acc[key] = route.query[key]
      }
      return acc
    }, {})
    if (route.params.referralCode) {
      params.r = route.params.referralCode
    }
    return Object.keys(params).length ? params : null
  })
  const routeQuery = computed(() => {
    const keys = ['promo', 'plan', 'prepayGroup']
    const query = keys.reduce((acc, key) => {
      if (route.query[key]) {
        acc[key] = route.query[key]
      }
      return acc
    }, referralQueryParams.value ?? {})
    return Object.keys(query).length ? { query } : null
  })
  const navigateWithStyleQuiz = computed(() =>
    ['/style-quiz', '/signup'].some(path => route.path.startsWith(path))
  )

  const { styleProfileUpdated } = storeToRefs(styleProfile)
  async function saveStyleProfileChanges () {
    if (styleProfileUpdated.value) {
      if (await styleProfile.saveStyleProfile()) {
        styleProfileUpdated.value = false
      }
    }
  }

  // Exclude screens between startScreenName and endScreenName, exclusive.
  function excludeScreensBetween (startScreenName, endScreenName) {
    const startIndex = screenNames.indexOf(startScreenName) + 1
    const endIndex = screenNames.indexOf(endScreenName)
    const names = screenNames.slice(0, startIndex).concat(screenNames.slice(endIndex))
    return names.reduce((acc, name) => {
      acc[name] = screens[name]
      return acc
    }, {})
  }

  function getProgressForSection (sectionScreenNames) {
    const currentSectionIndex = sectionScreenNames.indexOf(currentScreen.value.name)
    const lastSectionIndex = screenNames.indexOf(sectionScreenNames.at(-1))
    const currentScreenIndex = screenNames.indexOf(currentScreen.value.name)
    const totalSteps = sectionScreenNames.length
    // If we're past the last screen in this section, we're done with it.
    const step = currentScreenIndex > lastSectionIndex
      ? totalSteps
      : currentSectionIndex + 1
    return {
      step,
      totalSteps
    }
  }

  const seenScreens = ref(new Set())
  watch(currentScreen, ({ name }) => { seenScreens.value.add(name) })
  function isScreenComplete (screen) {
    return Object.hasOwn(screen, 'isComplete')
      ? toValue(screen.isComplete) === true
      : seenScreens.value.has(screen.name)
  }

  function getFirstScreenMatching (screenNames, predicate) {
    return screenNames.map(name => screens[name]).find(screen => predicate(screen)) ?? null
  }

  const router = useRouter()
  function startSignUp (signUpMode = SignUpMode.Default) {
    mode.value = signUpMode
    switch (signUpMode) {
      case SignUpMode.StyleQuiz:
        if (firstIncompleteStyleQuizScreen.value) {
          stepper.goTo(firstIncompleteStyleQuizScreen.value)
        } else {
          stepper.goTo(0)
        }
        break
      default:
        stepper.goTo(0)
    }
    router.push(currentScreenRouterLink.value)
  }

  async function next () {
    // Experiments are reset after sign-up, so we need to wait for them to reload.
    await useExperimentsStore().untilExperimentsLoaded()
    if (navigateWithStyleQuiz.value) {
      await styleQuizNav.next()
      return
    }
    if (nextScreen.value) {
      stepper.goToNext()
    } else if (nextScreenRouterLink.value) {
      router.push(nextScreenRouterLink.value)
    } else {
      logger.warn('No next screen or router link', currentScreen.value.name)
    }
  }

  function goTo (screenName) {
    // Drop 'sign-up-' prefix if present.
    screenName = screenName.replace(/^sign-up-/, '')
    // In the rare event that activeScreens doesn't include the current screen, we're
    // on a screen that's been excluded by the current mode so we'll reset to default.
    const activeScreenNames = Object.values(activeScreens.value).map(screen => screen.name)
    if (!activeScreenNames.includes(screenName)) {
      logger.log(`Screen '${screenName}' not in activeScreens, resetting mode to default`)
      mode.value = SignUpMode.Default
    }
    stepper.goTo(screenName)
  }

  return {
    mode,
    activeScreens,
    seenScreens,
    startUrl,
    currentScreen,
    currentScreenRouterLink,
    nextScreen,
    nextScreenRouterLink,
    progress,
    styleGameProgress,
    canSkipStyleGame,
    isStyleGameComplete,
    isStyleQuizComplete,
    firstIncompleteStyleQuizScreen,
    isPlanSurveyComplete,
    isPlanChoiceComplete,
    isCheckoutComplete,
    isMembershipComplete,
    getStartUrl,
    saveStyleProfileChanges,
    startSignUp,
    next,
    goTo
  }
})
