import { useSignUpExperiments } from '@shared/composables/experiment.js'
import { getSetters } from './helpers/shared'

// initial state
export const state = {
  baseImageUrl: 'https://dt002ezve8cvy.cloudfront.net/style_quiz_may21_images/',
  brandImageURLBase: 'https://dt002ezve8cvy.cloudfront.net/brands_2024/',
  isEvent: false,
  isPrepayFlow: false,
  isNonMember: true,
  isRejoiningMember: false,
  prepayGroup: null,
  styleQuizSectionsBase: [
    {
      display: true,
      name: 'Style Basics',
      id: 'basics',
      showInProgressAnimation: true,
      routes: [
        {
          phase2: true,
          name: 'style-quiz-intro',
          display: true,
          required: true,
          analyticsEventName: 'Viewed Style Quiz - Intro',
          notQuiz: true
        },
        {
          phase2: true,
          name: 'style-quiz-create-account',
          display: true,
          required: true,
          analyticsEventName: 'Viewed Style Quiz - Create Account',
          notQuiz: true
        }
      ]
    },
    {
      display: true,
      name: 'Style Details',
      id: 'details',
      showInProgressAnimation: true,
      routes: [
        {
          name: 'style-quiz-brands',
          display: true,
          required: true,
          pageHeader: 'HERE ARE SOME OF OUR FAVORITE BRANDS',
          pageSubheader: 'Which would you like to see in your closet?',
          analyticsEventName: 'Viewed Style Quiz - Brands',
          endOfSection: 1
        }
      ]
    },
    {
      display: true,
      name: 'Sizing + Fit',
      id: 'fit',
      showInProgressAnimation: true,
      routes: [
        {
          phase2: true,
          name: 'style-quiz-measurements',
          display: true,
          required: true,
          analyticsEventName: 'Viewed Style Quiz - Measurements'
        },
        {
          phase2: true,
          name: 'style-quiz-due-date',
          display: false,
          required: true,
          analyticsEventName: 'Viewed Style Quiz - Due Date'
        },
        {
          phase2: true,
          name: 'style-quiz-sizes',
          display: true,
          required: true,
          analyticsEventName: 'Viewed Style Quiz - Sizes'
        },
        {
          phase2: true,
          name: 'style-quiz-birthday',
          display: true,
          required: true,
          analyticsEventName: 'Viewed Style Quiz - Birthday',
          endOfSection: 2
        }
      ]
    },
    {
      display: true,
      name: 'Choose Membership',
      id: 'choose-membership',
      showInProgressAnimation: false,
      routes: [
        {
          phase2: true,
          name: 'style-quiz-choose-membership',
          display: true,
          required: true,
          analyticsEventName: 'Viewed Style Quiz - Choose Membership',
          notQuiz: true
        },
        {
          name: 'style-quiz-prepay-plan',
          display: false,
          required: true,
          analyticsEventName: 'Viewed Style Quiz - Prepay Plan',
          notQuiz: true
        },
        {
          phase2: true,
          name: 'subscribe',
          display: true,
          required: true,
          analyticsEventName: 'Viewed Style Quiz - Subscribe',
          notQuiz: true
        }
      ]
    },
    {
      display: true,
      name: 'Complete',
      id: 'style-quiz-complete',
      notQuiz: true,
      routes: [
        {
          phase2: true,
          name: 'style-quiz-success',
          display: true,
          required: true,
          analyticsEventName: 'Viewed Style Quiz - Completed',
          notQuiz: true
        }
      ]
    }
  ],
  eventSignupSections: [
    'style-quiz-create-account',
    'style-quiz-choose-membership',
    'subscribe'
  ]
}

export const getters = {
  styleQuizSections: (state, getters, rootState) => {
    // eslint-disable-next-line
    let sections =  []
    const filterOutChooseMembershipPrepay = (routes) => {
      if (state.isPrepayFlow) {
        return routes.filter((route) => {
          return route.name !== 'style-quiz-choose-membership'
        })
      }
      return routes
    }

    state.styleQuizSectionsBase.filter((section) => {
      // if a client is already active, don't show the membership pages, just success
      if (rootState?.client?.membershipStatus === 'active') {
        return true
      } else {
        return !(section.id === 'style-quiz-complete')
      }
    }).forEach((section) => {
      sections.push({ ...section, routes: filterOutChooseMembershipPrepay(section.routes) })
    })
    return sections
  },
  styleQuizRoutes: (state, getters) => {
    const { signUpPhase2 } = useSignUpExperiments()
    let routes = []
    for (const section of getters.styleQuizSections) {
      const sectionRoutes = section.routes.reduce((aggregator, route) => {
        return route.display && (!signUpPhase2.value || route.phase2)
          ? aggregator.concat(route)
          : aggregator
      }, [])
      routes = routes.concat(sectionRoutes)
    }
    return routes
  },
  styleQuizEventRoutes: (state, getters) => {
    return getters.styleQuizRoutes.filter((route) => {
      return state.eventSignupSections.includes(route.name)
    })
  },
  getCurrentStyleQuizRoute: (state, getters) => ({ name, params }) => {
    const routes = getters.styleQuizRoutes
    const routeIndex = routes.findIndex(route =>
      route.name === name &&
        (!route.paramsEqualityCheck || route.paramsEqualityCheck(params)))

    return (routeIndex > -1 && routeIndex < routes.length - 1)
      ? getters.styleQuizRoutes[routeIndex]
      : null
  },
  getNextStyleQuizRoute: (state, getters) => ({ name, params, query }) => {
    const isEvent = !!query.isEvent
    const routes = isEvent ? getters.styleQuizEventRoutes : getters.styleQuizRoutes

    const currentIndex = routes.findIndex(route =>
      route.name === name &&
      (!route.paramsEqualityCheck || route.paramsEqualityCheck(params)))

    return (currentIndex > -1 && currentIndex < routes.length - 1)
      ? routes[currentIndex + 1]
      : null
  },
  getNextStyleQuizRouteForRouter: (state, getters) => ({ name, params, query }) => {
    const nextStyleQuizRoute = getters.getNextStyleQuizRoute({ name, params, query })

    return nextStyleQuizRoute
      ? { name: nextStyleQuizRoute.name, params: nextStyleQuizRoute.params || {} }
      : null
  },
  shouldShowNextButtonForRoute: (state, getters, rootState) => ({ name, params }) => {
    const route = getters.getCurrentStyleQuizRoute({ name, params })
    if (route && route.canShowNextButton) {
      switch (route.name) {
        case 'style-quiz-style-clusters':
          return rootState.styleProfile.clusterRatings &&
            rootState.styleProfile.clusterRatings[params.cluster] !== undefined
        default:
          return false
      }
    }
  }
}

export const mutations = {
  ...getSetters(state),
  'SET_STYLE_QUIZ_ROUTE_DISPLAYED' (state, data) {
    const name = data.name
    for (const section of state.styleQuizSectionsBase) {
      const route = section.routes.find(x => x.name === name)
      if (route) route.display = data.display
    }
  },
  'UPDATE_STYLE_QUIZ_CLUSTER_ROUTES' (state, data) {
    for (const cluster in data) {
      state.styleQuizSectionsBase[0].routes.push(
        ({
          name: 'style-quiz-style-clusters',
          params: { cluster: data[cluster].value },
          paramsEqualityCheck: (params) => data[cluster].value === params.cluster,
          canShowNextButton: true,
          display: true,
          required: true,
          pageHeader: 'YOUR STYLE',
          pageSubheader: `How often would you like to see ${data[cluster].value} styles in your closet?`,
          analyticsEventName: `Viewed Style Quiz - Style Cluster ${data[cluster].value}`
        })
      )
    }
    state.styleQuizSectionsBase[0].routes[state.styleQuizSectionsBase[0].routes.length - 1].endOfSection = 0
  }
}
