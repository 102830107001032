<template>
  <BaseStickyFooter
    :is-visible="show"
    class="position-relative pt-md pb-lg">
    <template #content>
      <div class="w-100 d-flex flex-column align-items-center row-gap-lg">
        <TrackingButton
          :text="buttonText"
          app-entry-point="signup"
          :event-label="`Footer ${buttonText}`"
          class="w-100"
          :to="nextScreenRouterLink"
          @click="emit('next')"/>
        <slot name="additional-content"/>
      </div>
    </template>
  </BaseStickyFooter>
</template>

<script setup>
import { useSignUpNavigationStore } from '@/stores/signUpNavigation.js'
import TrackingButton from '@shared/components/ADORN/TrackingButton.vue'
import BaseStickyFooter from '@shared/components/ADORN/BaseStickyFooter.vue'
import { storeToRefs } from 'pinia'
import { computed, toValue } from 'vue'

const signUpNav = useSignUpNavigationStore()
const { currentScreen, nextScreenRouterLink } = storeToRefs(signUpNav)
const show = computed(() => toValue(currentScreen.value.footer?.show) ?? false)
const buttonText = computed(() => currentScreen.value.footer?.buttonText ?? 'Next')

const emit = defineEmits(['next'])
</script>
