import api from '../api/api'
import { getSetters } from './helpers/shared'
import logger from '../../logger'
import getKeyedPromise from '@/utils/keyedPromise.js'

export const state = {
  id: null,

  signupReasons: [],
  signupReasonOptions: [],

  signupEvents: [],
  eventsOptions: [],

  selectedBrands: [],
  brandOptions: [],

  avoids: [],
  avoidsOptions: [],

  selectedOccasions: [],
  occasionOptions: [],

  selectedCategories: [],
  categoryOptions: [],

  selectedSeasons: [],
  seasonOptions: [],

  heightFeet: null,
  heightFeetOptions: [],
  heightInches: null,
  heightInchesOptions: [],

  braSizeBand: null,
  braSizeBandOptions: [],

  // braSizeCup backing field bra_size_cup cannot be null only blank, but this is cleaner
  braSizeCup: null,
  braSizeCupOptions: [],

  bodyShape: null,
  bodyShapeOptions: [],

  dressSizes: [],
  jumpsuitSizes: [],
  shirtSizes: [],
  waistSizes: [],
  pantSizes: [],

  letterSizeMapping: {},
  sizeLetterMapping: {},

  waistSizeOptions: [],

  birthDate: null,
  isMaternityCustomer: 'false',
  dueDate: null,
  showMaternity: null,
  showNursing: null,
  zipCode: '',

  leadSource: null,
  leadSourceOptions: [],

  leadSourceDetail: null,

  clusterRatings: {},
  generalClusterRatingOptions: [],
  generalClusterOptions: [],

  // Loaders
  styleProfileLoading: true,
  styleProfileOptionsLoading: true,
  styleProfileQuizOptionsFetched: false,

  // Used for moving Maternity A/B Test
  showMaternitySection: false,

  selectedMaternity: [],
  maternityOptions: [],

  allSizeOrders: {},
  plusSizeOptions: [],

  // Used for Plan Choice Survey
  planChoiceFrequency: null,
  planChoiceFrequencyOptions: [],

  planChoiceSpend: null,
  planChoiceSpendOptions: [],

  // Used for Size Selection
  sizingTypes: [
    {
      value: 'standard',
      text: 'Standard',
      sizes: [],
      visible: false
    },
    {
      value: 'plus',
      text: 'Plus',
      sizes: [],
      visible: false
    },
    {
      value: 'waist',
      text: 'Pants',
      sizes: [],
      visible: false
    }
  ]
}

export const signupReasonsResponseKey = {
  brands: 'luxury',
  experimenting: 'unlimited',
  money: 'unlimited',
  time: 'convenient',
  owning: 'unlimited',
  sustainability: 'sustainable',
  styling: 'personalized',
  maternity: 'maternity'
}

export const getters = {
  waistSizeType: state => state.sizingTypes.find(x => x.value === 'waist'),
  standardSizeType: state => state.sizingTypes.find(x => x.value === 'standard'),
  plusSizeType: state => state.sizingTypes.find(x => x.value === 'plus'),
  letterSizeOptions: state => { return Object.keys(state.letterSizeMapping) },
  waistSizeOptionsMapped: (state) => {
    return state.waistSizeOptions.map(size => {
      return {
        text: size.text,
        value: size.value
      }
    })
  },
  numberToLetterSizeOptions: (state, getters) => (plusSizes) => {
    return getters.letterSizeOptions.reduce((prev, letterSizeOption) => {
      const filteredSizes = state.letterSizeMapping[letterSizeOption]
        .filter(size => plusSizes ? state.plusSizeOptions.includes(size) : !state.plusSizeOptions.includes(size))
      if (plusSizes) {
        const mappedOptions = filteredSizes.map(size => ({
          text: `${size}`,
          value: size
        }))
        return prev.concat(mappedOptions)
      } else {
        const mappedOptions = filteredSizes.map(size => ({
          text: `${size} (${letterSizeOption})`,
          value: size
        }))
        return prev.concat(mappedOptions)
      }
    }, [])
  }
}

export const actions = {
  async getStyleProfileOptions ({ commit, getters, dispatch, state }) {
    if (!state.styleProfileQuizOptionsFetched) {
      // BUGBUG Why no try/catch around this?  If it fails we could be in a weird state
      // BUGBUG Why not set & clear PROFILE_OPTIONS_LOADING like we do with getStyleProfile?
      const res = await api.apiStyleProfile.getStyleProfileOptions()
      commit('SET_STYLE_PROFILE', res.data)
      commit('styleQuizNav/UPDATE_STYLE_QUIZ_CLUSTER_ROUTES', res.data.generalClusterOptions, { root: true })
      commit('SET_SIZE_TYPES_SIZES', { type: 'standard', sizes: getters.numberToLetterSizeOptions() })
      commit('SET_SIZE_TYPES_SIZES', { type: 'plus', sizes: getters.numberToLetterSizeOptions(true) })
      commit('SET_SIZE_TYPES_SIZES', { type: 'waist', sizes: getters.waistSizeOptionsMapped })
      commit('SET_STYLE_PROFILE_OPTIONS_LOADING', false)
      commit('SET_STYLE_PROFILE_QUIZ_OPTIONS_FETCHED', true)
    }
    dispatch('setInitialCategoryState')
  },
  async getStyleProfile ({ commit, dispatch }) {
    return getKeyedPromise('styleProfile/getStyleProfile', async (resolve, reject) => {
      try {
        commit('SET_STYLE_PROFILE_LOADING', true)
        const res = await api.apiStyleProfile.getStyleProfile()
        logger.info(res.data)
        // BUGBUG Why not call await?  It is synchronous but it's nice to be explicit
        dispatch('loadStyleProfile', res.data)
        commit('SET_STYLE_PROFILE_LOADING', false)
        dispatch('setInitialCategoryState')
        resolve(res.data)
      } catch (err) {
        logger.error(err)
        commit('SET_STYLE_PROFILE_LOADING', false)
        reject(err)
      }
    })
  },
  async patchStyleProfile ({ dispatch }) {
    const data = await dispatch('serializeStyleProfile')
    await api.apiStyleProfile.patchStyleProfile(data)
  },
  async saveGlobalFilters ({ commit, dispatch }, filters) {
    commit(
      'styleProfile/SET_STYLE_PROFILE',
      {
        selectedOccasions: filters.occasions,
        selectedCategories: filters.categories,
        selectedSeasons: filters.seasons
      },
      { root: true })
    dispatch('patchStyleProfile')
  },
  loadStyleProfile ({ commit }, data) {
    // Fix up data returned from the backend
    logger.info('Original data', data)

    // BUGBUG LATER - Check if we are going to overwrite the current store with null/empty values,
    // say if the get() API failed somehow or serialization went wonky.
    // For now, check if there's any key or value and only if there is convert it
    if (data.braSizeBand) data.braSizeBand = String(data.braSizeBand)
    if (data.heightFeet) data.heightFeet = String(data.heightFeet)
    if (Number.isInteger(data.heightInches)) data.heightInches = String(data.heightInches)

    if (data.birthDate) {
      const d = data.birthDate.split('-')
      data.birthDate = [d[1], d[2], d[0]].join('/')
    }

    if (data.dueDate) {
      const d = data.dueDate.split('-')
      data.dueDate = [d[1], d[2], d[0]].join('/')
    }

    logger.info('Cleaned data', data)
    commit('SET_STYLE_PROFILE', data)
  },
  serializeStyleProfile ({ state }) {
    const s = state
    const req = {}

    if (Object.keys(s.clusterRatings).length > 0) req.clusterRatings = s.clusterRatings
    if (s.selectedBrands.length > 0) req.selectedBrands = s.selectedBrands
    if (s.signupReasons.length > 0) req.signupReasons = s.signupReasons

    if (s.avoids.length > 0) req.avoids = s.avoids
    if (s.signupEvents.length > 0) req.signupEvents = s.signupEvents

    if (s.selectedOccasions.length > 0) req.selectedOccasions = s.selectedOccasions
    if (s.selectedCategories.length > 0) req.selectedCategories = s.selectedCategories
    if (s.selectedSeasons.length > 0) req.selectedSeasons = s.selectedSeasons

    if (s.heightFeet !== null) req.height_feet = s.heightFeet
    if (s.heightInches !== null) req.height_inches = s.heightInches
    if (s.braSizeBand !== null) req.bra_size_band = s.braSizeBand
    if ((s.braSizeCup !== null) && (s.braSizeCup !== '')) req.bra_size_cup = s.braSizeCup
    if (s.bodyShape !== null) req.body_shape = s.bodyShape

    if (s.dressSizes.length > 0) req.dress_sizes = s.dressSizes
    if (s.jumpsuitSizes.length > 0) req.jumpsuit_sizes = s.jumpsuitSizes
    if (s.pantSizes.length > 0) req.pant_sizes = s.pantSizes
    if (s.shirtSizes.length > 0) req.shirt_sizes = s.shirtSizes
    if (s.waistSizes.length > 0) req.waist_sizes = s.waistSizes

    if (s.planChoiceFrequency !== null) req.plan_choice_frequency = s.planChoiceFrequency
    if (s.planChoiceSpend !== null) req.plan_choice_spend = s.planChoiceSpend

    if ((s.birthDate !== null) && s.birthDate.includes('/')) {
      const d = s.birthDate.split('/')
      req.birth_date = [d[2], d[0], d[1]].join('-')
    }

    if ((s.dueDate !== null) && s.dueDate.includes('/')) {
      const d = s.dueDate.split('/')
      req.due_date = [d[2], d[0], d[1]].join('-')
      req.selectedMaternity = s.selectedMaternity
      if (s.selectedMaternity) {
        req.show_maternity = s.selectedMaternity.includes('maternity')
        req.show_nursing = s.selectedMaternity.includes('nursing')
        req.show_maternity_friendly = s.selectedMaternity.includes('maternity_friendly')
        req.show_non_maternity = s.selectedMaternity.includes('non_maternity')
      } else {
        req.show_maternity = false
        req.show_nursing = false
        req.show_maternity_friendly = false
        req.show_non_maternity = false
      }
    } else {
      req.due_date = null
      req.show_maternity = false
      req.show_nursing = false
      req.show_maternity_friendly = false
      req.show_non_maternity = false
    }

    if (s.leadSource !== null) req.lead_source = s.leadSource
    if (s.leadSourceDetail != null) req.lead_source_detail = s.leadSourceDetail
    logger.info(req)
    return req
  },
  setSignupEvents ({ commit }, signupEvents) {
    commit('SET_SIGNUP_EVENTS', signupEvents)
  },
  setPlanChoiceFrequency ({ commit }, planChoiceFrequency) {
    commit('SET_PLAN_CHOICE_FREQUENCY', planChoiceFrequency)
  },
  setPlanChoiceSpend ({ commit }, planChoiceSpend) {
    commit('SET_PLAN_CHOICE_SPEND', planChoiceSpend)
  },
  setVisibleSizeTypes ({ commit }, { category, visible }) {
    commit('SET_SIZE_TYPE_VISIBLE', { category, visible })
  },
  async setSizesFromStyleQuiz ({ commit }, { sizeData }) {
    commit('SET_SIZES', { category: sizeData.ref, sizes: sizeData.sizes })
  },
  async setSizesFromStyleProfile ({ commit }, { sizeData }) {
    commit('SET_SIZES', { category: sizeData.ref, sizes: sizeData.sizes })
  },
  setInitialCategoryState ({ state, commit, getters }) {
    if (!state.styleProfileLoading && !state.styleProfileOptionsLoading) {
      const allSelectedSizes = [...state.shirtSizes, ...state.dressSizes, ...state.jumpsuitSizes, ...state.pantSizes]
      const isSizeSelected = (isPlusSize) => {
        const sizeOptions = getters.numberToLetterSizeOptions(isPlusSize).map(size => size.value)
        return sizeOptions.some(item => allSelectedSizes.includes(item))
      }
      if (isSizeSelected(false)) {
        commit('SET_SIZE_TYPE_VISIBLE', { category: 'standard', visible: true })
      }
      if (isSizeSelected(true)) {
        commit('SET_SIZE_TYPE_VISIBLE', { category: 'plus', visible: true })
      }
    }
  },
  setSignupReasons ({ commit }, signupReasons) {
    commit('SET_SIGNUP_REASONS', signupReasons)
  }
}

export const mutations = {
  ...getSetters(state),
  'SET_STYLE_PROFILE' (state, data) {
    Object.keys(data).forEach(key => {
      state[key] = data[key]
    })
  },
  'SET_SELECTED_OPTIONS' (state, data) {
    const preselects = data.preselects
    preselects.forEach(preselect => {
      const optionsKey = `${preselect}Options`
      const selectKey = `selected${preselect.charAt(0).toUpperCase()}${preselect.slice(1)}s`
      state[selectKey] = [...data.options[optionsKey].map(x => x.value)]
    })
    // special case, because, you know, the English language
    if ('categoryOptions' in data.options) {
      state.selectedCategories = [...data.options.categoryOptions.map(x => x.value)]
      delete state.selectedCategorys
    }
  },
  'SET_SIZE_TYPES_SIZES' (state, { type, sizes }) {
    switch (type) {
      case 'standard':
        state.sizingTypes.find(x => x.value === 'standard').sizes = sizes
        break
      case 'plus':
        state.sizingTypes.find(x => x.value === 'plus').sizes = sizes
        break
      case 'waist':
        state.sizingTypes.find(x => x.value === 'waist').sizes = sizes
        break
    }
  },
  'SET_SIZE_TYPE_VISIBLE' (state, { category, visible }) {
    switch (category) {
      case 'standard':
        state.sizingTypes.find(x => x.value === 'standard').visible = visible
        break
      case 'plus':
        state.sizingTypes.find(x => x.value === 'plus').visible = visible
        break
    }
  },
  'SET_SIZES' (state, { category, sizes }) {
    switch (category) {
      case 'shirts':
        state.shirtSizes = sizes
        break
      case 'waist':
        state.waistSizes = sizes
        break
      case 'dresses':
        state.dressSizes = sizes
        break
      case 'pants':
        state.pantSizes = sizes
        break
      case 'jumpsuits':
        state.jumpsuitSizes = sizes
        break
      case 'allSizes':
        state.pantSizes = state.jumpsuitSizes = state.shirtSizes = state.dressSizes = sizes
        break
    }
  },
  'SET_SIGNUP_EVENTS' (state, signupEvents) {
    state.signupEvents = signupEvents
  },
  'FILTER_SIZE_SELECTIONS' (state, filteredSizes) {
    state.pantSizes = state.jumpsuitSizes = state.shirtSizes = state.dressSizes = filteredSizes
  },
  'SET_SIGNUP_REASONS' (state, signupReasons) {
    state.signupReasons = signupReasons
  }
}
