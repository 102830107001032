<template>
  <div v-if="membershipPlanSelected">
    <div
      class="d-flex justify-content-center"
      :class="{'flex-column' : isMobile}">
      <div
        class="me-lg-4 me-md-3 pb-4"
        :class="{'d-flex justify-content-center' : isMobile}">
        <plan-card
          :form-disabled="formDisabled"
          :plan="membershipPlanSelected"
          show-change-plan-button
          header-text="Selected Plan"
          @change-click="onChangePlanClick"/>
      </div>
      <div
        v-if="loadingPaymentMethodInfo"
        key="loading"
        class="subscribe-actions d-flex pt-5 justify-content-center"
        :class="{ 'desktop': !isMobile }">
        <svg-spinner height="40"/>
      </div>
      <div
        class="bg-white text-start subscribe-actions"
        :class="{ 'border desktop': !isMobile, 'border-top pt-3': isMobile && !showPromoBanner && !showReferralBanner }">
        <subscribe-address
          :expanded="expandedSection === 'address'"
          :class="{'col px-4': isMobile}"
          :form-disabled="formDisabled"
          @address-added="addressAdded"
          @edit-click="expandedSection = 'address'"
          @exit-click="getNextExpandedSection"/>
        <subscribe-payment
          class="border-top"
          :class="{'col px-4': isMobile }"
          :expanded="expandedSection === 'payment-method'"
          :stripe="stripe"
          :card="card"
          :token="stripeToken"
          :can-show-payment-request="canShowPaymentRequest"
          :apple-pay="showApplePay"
          :form-disabled="formDisabled"
          @token-updated="stripeTokenUpdated"
          @edit-click="expandedSection = 'payment-method'"
          @exit-click="getNextExpandedSection"
          @edit-payment-mounted="createCard('card-element')"/>
        <subscribe-summary
          :class="{'col px-4': isMobile }"
          :expanded="expandedSection === 'summary'"
          :form-disabled="formDisabled"
          :fine-print-text="finePrintText"
          @review-click="expandedSection = 'summary'">
          <div
            v-if="stripeErrorMessage"
            class="ext-center">
            <p class="small-text text-danger">
              {{ stripeErrorMessage }}
              <br><span v-html="stripeErrorFollowup"/>
            </p>
          </div>
          <sequin-button
            id="btn-stripe"
            :disabled="(!stripeToken && !canShowPaymentRequest) || !shippingAddress || !leadSource || (leadSourceDetailRequired && !leadSourceDetail) || formDisabled"
            block
            class="mt-2 mb-4 d-flex justify-content-center"
            :class="{ 'apple-pay-button' : shippingAddress && showApplePay && !stripeToken }"
            @click="confirmAndPay">
            {{ confirmButtonText }}
            <div
              v-if="shippingAddress && showApplePay && !stripeToken"
              class="apple-pay-logo"/>
          </sequin-button>
        </subscribe-summary>
      </div>
    </div>
  </div>
</template>

<script>
import PlanCard from './PlanCard'
import SubscribeAddress from './SubscribeAddress'
import SubscribePayment from './SubscribePayment'
import SubscribeSummary from './SubscribeSummary'
import SequinButton from '../global/sequin/SequinButton'
import { reportToRakuten } from '../global/conversion/rakuten'
import usePayment from '@shared/composables/payment.js'
import useStripe from '@shared/composables/stripe.js'
import { mapState, mapActions as mapVuexActions, mapGetters } from 'vuex'
import { mapActions } from 'pinia'
import useAnalytics from '@shared/composables/analytics.js'
import usePrepay from '@shared/composables/prepay.js'
import { useOverlaysStore } from '@/stores/overlays.js'
import { formatPrice } from '@/utils/stringParsing.js'
import { MobileAppMessage, postToMobileWebView } from '@/utils/mobileApp.js'
import SvgSpinner from '@/components/global/svg/SvgSpinner.vue'

export default {
  name: 'SubscribePage',
  components: {
    PlanCard,
    SequinButton,
    SubscribeAddress,
    SubscribePayment,
    SubscribeSummary,
    SvgSpinner
  },
  setup () {
    const { track } = useAnalytics()
    const {
      card,
      stripe,
      loadStripe,
      untilStripeLoaded,
      initializeStripe,
      createCard
    } = useStripe()
    const { promoName, promoAmount, subtotal, total } = usePayment()

    const { prepayPlanGroup } = usePrepay()

    return {
      promoName,
      promoAmount,
      subtotal,
      total,
      card,
      stripe,
      prepayPlanGroup,
      loadStripe,
      untilStripeLoaded,
      initializeStripe,
      createCard,
      track
    }
  },
  data () {
    return {
      expandedSection: 'address',
      stripeErrorMessage: null,
      stripeErrorFollowup: null,
      paymentRequest: null,
      canShowPaymentRequest: false,
      showApplePay: false,
      formDisabled: false,
      loadingPaymentMethodInfo: true
    }
  },
  computed: {
    ...mapState('subscribe', [
      'membershipPlans',
      'stripeToken',
      'referralCode',
      'referralSource',
      'leadSource',
      'leadSourceDetail',
      'leadSourceDetailRequired',
      'enteredCodes',
      'tax'
    ]),
    ...mapState('client', [
      'firstName',
      'lastName',
      'email',
      'mainPhone',
      'shippingAddress',
      'zipCode',
      'defaultPayment',
      'username',
      'membershipStatus'
    ]),
    ...mapState('styleQuizNav', [
      'isPrepayFlow'
    ]),
    ...mapGetters('client', [
      'selectedStandardItems'
    ]),
    ...mapGetters('subscribe', [
      'membershipPlanSelected',
      'showPromoBanner',
      'showReferralBanner'
    ]),
    ...mapGetters('closet', [
      'selectedAvailableItemTypes'
    ]),
    confirmButtonText () {
      if (!this.shippingAddress) return 'Shipping Address Required'
      if (!this.canShowPaymentRequest && !this.stripeToken) return 'Payment Method Required'
      if (!this.stripeToken && this.showApplePay) return 'Subscribe with '
      return `Confirm and ${this.canShowPaymentRequest && !this.stripeToken ? 'Review Express Payment' : 'Pay'} — ${formatPrice(this.total, false, true)}`
    },
    finePrintText () {
      if ((!this.stripeToken && !this.canShowPaymentRequest) || !this.shippingAddress || !this.leadSource) return ''
      if (!this.stripeToken && this.showApplePay) return this.confirmButtonText + 'Apple Pay'
      return this.confirmButtonText
    }
  },
  watch: {
    total () {
      if (this.paymentRequest) {
        this.paymentRequest.update({
          total: {
            label: 'Armoire subscription',
            amount: Math.round(this.total * 100)
          }
        })
      }
    }
  },
  created () {
    this.loadStripe()
  },
  mounted () {
    this.initializeElements()
  },
  methods: {
    ...mapVuexActions('subscribe', [
      'subscribeClient',
      'getCasePrice',
      'clearAllPromos',
      'callGetSubscriptionQuote'
    ]),
    ...mapVuexActions('closet', [
      'submitCloset'
    ]),
    ...mapActions(useOverlaysStore, [
      'openFlyout'
    ]),
    ...mapVuexActions('case', [
      'setMyCaseFlyoutName',
      'setMyCaseFlyoutContext'
    ]),
    ...mapVuexActions('client', [
      'postDefaultPayment'
    ]),
    formatPrice,
    async initializeElements () {
      await this.untilStripeLoaded()
      await this.initializeStripeElement()
      if (this.shippingAddress) {
        this.$logger.info('Shipping address already present, getting subscription quote', this.shippingAddress)
        await this.callGetSubscriptionQuote()
      }
      this.getNextExpandedSection()
    },
    onChangePlanClick () {
      this.clearAllPromos()
      if (this.isPrepayFlow) {
        this.$router.push({ name: 'style-quiz-prepay-plan', query: { prepayGroup: this.prepayPlanGroup.name } })
      } else {
        this.$router.push({ name: 'style-quiz-choose-membership' })
      }
    },
    addressAdded () {
      this.track('Subscribe - Shipping Address Added')
      this.getNextExpandedSection()
      this.callGetSubscriptionQuote()
    },
    stripeTokenUpdated (token) {
      this.$store.commit('subscribe/SET_STRIPE_TOKEN', token)
      if (this.stripeErrorMessage) this.stripeErrorMessage = null
      if (this.stripeErrorFollowup) this.stripeErrorFollowup = null
      this.getNextExpandedSection()
    },
    getNextExpandedSection () {
      let expandedSection = 'summary'
      if (!this.shippingAddress) expandedSection = 'address'
      else if (!this.stripeToken && !this.canShowPaymentRequest) expandedSection = 'payment-method'
      this.$logger.info('Setting expanded section to', expandedSection)
      this.expandedSection = expandedSection
    },
    async payWithRequestPayment () {
      this.paymentRequest.show()
    },
    async confirmAndPay () {
      // Send the token to the server.
      if (this.stripeToken) await this.subscribeClientWithToken(this.stripeToken)
      else if (this.canShowPaymentRequest) await this.payWithRequestPayment()
    },
    async initializeStripeElement () {
      this.$logger.info('Initializing Stripe Element')
      const elements = await this.initializeStripe()
      this.$logger.info('Stripe initialized')
      const paymentRequest = this.stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Armoire subscription',
          amount: Math.round(this.total * 100)
        },
        requestPayerName: true,
        requestPayerEmail: true
      })
      this.paymentRequest = paymentRequest

      this.$logger.info('Creating Stripe payment request button')
      // eslint-disable-next-line
      await elements.create('paymentRequestButton', {
        paymentRequest: paymentRequest
      })

      // Check the availability of the Payment Request API first.
      paymentRequest.canMakePayment().then(this.canMakePaymentCallback)
      paymentRequest.on('token', this.paymentRequestCallback)
      this.$logger.info('Finished initializing Stripe Element')
    },
    canMakePaymentCallback (result) {
      if (result) {
        this.canShowPaymentRequest = true
        if (result.applePay) {
          this.showApplePay = true
        }
      }
      this.loadingPaymentMethodInfo = false
      if ((this.canShowPaymentRequest || this.stripeToken) && this.shippingAddress) this.expandedSection = 'summary'
    },
    paymentRequestCallback (res) {
      this.subscribeClientWithToken(res.token, res)
      this.track('Express payment used')
    },
    getLeadSource () {
      return {
        name: this.leadSource,
        detail: this.leadSourceDetail,
        utmCampaign: sessionStorage.getItem('utm_campaign'),
        utmMedium: sessionStorage.getItem('utm_medium'),
        utmSource: sessionStorage.getItem('utm_source')
      }
    },
    async reportSubscription (orderCompleteData, res) {
      try {
        const analyticsData = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          phone: this.mainPhone.replace(/-/g, ''),
          zipCode: this.zipCode,
          promoCode: this.membershipPlanSelected?.appliedPromo.promoCode || '',
          planName: this.membershipPlanSelected.name,
          planId: this.membershipPlanSelected.id,
          leadSource: this.leadSource,
          leadSourceDetail: this.leadSourceDetail,
          order_id: res.data.rakuten.orderid,
          affiliation: 'Armoire',
          total: orderCompleteData.total,
          revenue: orderCompleteData.subtotal,
          value: orderCompleteData.subtotal,
          shipping: 0,
          tax: orderCompleteData.tax,
          discount: orderCompleteData.discount,
          coupon: orderCompleteData.coupon,
          currency: 'USD',
          products: [
            {
              product_id: this.membershipPlanSelected.id,
              name: this.membershipPlanSelected.name,
              price: orderCompleteData.subtotal,
              quantity: 1,
              category: 'Subscription'
            }
          ]
        }
        this.$logger.info(analyticsData)
        this.track('Order Completed', analyticsData)
        // DO NOT REMOVE
        // This looks redundant but it is necessary.  Segment does special handling of
        // Order Completed that doesn't work well for Facebook, they map it to Purchased.
        // We work around Segment by sending a second different event even though it has the same payload.
        this.track('Client Subscribed - Facebook', analyticsData)
        reportToRakuten(res.data.rakuten)
      } catch (err) {
        // don't stop on or surface errors that are analytics/3rd party based
        this.$logger.error(err)
      } finally {
        postToMobileWebView(MobileAppMessage.Subscribed, res.data)
      }
    },
    async completeSubscription (onComplete) {
      if (onComplete) {
        onComplete.complete('success')
      }
      this.$router.push({ name: 'subscribe-success' })
    },
    async subscribeClientWithToken (token, onComplete = null) {
      this.formDisabled = true
      const data = {
        token: token,
        upgradeCode: '',
        plan: this.membershipPlanSelected.name,
        leadSource: this.getLeadSource(),
        promo_codes: this.enteredCodes
      }
      if (this.referralCode) {
        data.referrer_code = this.referralCode
        data.referral_source = this.referralSource
      }
      // create a copy of this data to use in the Segment Order Completed
      // event, since it gets cleared as part of subscribeClient
      const orderCompleteData = {
        tax: (this.tax / 100).toFixed(2),
        subtotal: this.subtotal.toFixed(2),
        total: this.total,
        discount: Number.parseFloat(this.promoAmount).toFixed(2),
        coupon: this.promoName
      }
      let sendAnalytics = true
      let subResponse = null
      try {
        if (this.membershipStatus !== 'active') {
          subResponse = await this.subscribeClient(data)
          if ('stripeError' in subResponse.data) {
            this.$logger.error('subscribeClient failure', subResponse.data)
            this.stripeErrorMessage = subResponse.data.stripeError.errorMessage
            this.stripeErrorFollowup = subResponse.data.stripeError.errorFollowup
            this.$store.commit('subscribe/SET_STRIPE_TOKEN', null)
            sendAnalytics = false
            if (onComplete) {
              onComplete.complete('fail')
              return
            }
          }
        } else {
          await this.postDefaultPayment(token)
        }
        await this.completeSubscription(onComplete, orderCompleteData)
      } catch (err) {
        this.$logger.error(err)
        this.stripeErrorMessage = `There was an issue confirming your subscription. Please ${this.stripeToken ? 're-enter your payment information above and' : ''} try again.`
        this.stripeErrorFollowup = ''
        err.forEach(error => { this.stripeErrorFollowup += `<br>${error}` })
        this.$store.commit('subscribe/SET_STRIPE_TOKEN', null)
      }
      this.formDisabled = false
      if (sendAnalytics) {
        await this.reportSubscription(orderCompleteData, subResponse)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.border {
  border-radius: $sequin-border-radius;
}

.sticky-top {
  top: 16;
  height: fit-content;
}

.apple-pay-button {
  background-color: black;
  width: 100%;
  text-align: right;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI' !important;
  font-weight: $font-weight-semibold !important;

  &:hover {
    background-color: black;
  }

  .apple-pay-logo {
    background-image: -webkit-named-image(apple-pay-logo-white);
    background-size: 100% 100%;
    background-origin: content-box;
    background-repeat: no-repeat;
    height: 20px;
    width: 52px;
    margin-left: 4px;
  }
}

.subscribe-actions {
  width: 100%;
  height: fit-content;

  &.desktop {
    flex: 0 2 55%;
    max-width: 560px;
  }
}
</style>
