<template>
  <section class="d-flex flex-column">
    <h5
      v-if="!isTabletOrSmaller"
      class="text-center">
      Example Case
    </h5>
    <div class="mb-lg mx-auto">
      <div class="first-row d-grid d-lg-flex gap-sm justify-content-lg-center w-100">
        <img
          v-for="index in getFirstRowItems(numItems[0])"
          :key="index"
          :src="getProductImage(planId, index + 1)"
          alt="Product Image"
          class="product-image">
      </div>
      <div
        v-if="getSecondRowItems(numItems[0]).length > 0"
        class="second-row d-flex gap-sm justify-content-center">
        <img
          v-for="index in getSecondRowItems(numItems[0])"
          :key="index"
          :src="getProductImage(planId, index + 1)"
          alt="Product Image"
          class="product-image">
      </div>
    </div>

    <div class="scroll-loop-container container">
      <TickerLoopScroll
        :items="makeArray(numItems[1])"
        :custom-width="84"
        :duration="scrollDuration(numItems[1])">
        <template #default="{ item, index }">
          <img
            :key="item"
            :src="getCarouselImage(planId, index+1)"
            alt="Product Image"
            class="carousel-image">
        </template>
      </TickerLoopScroll>
    </div>
  </section>
</template>
<script setup>
import { computed, defineProps } from 'vue'
import TickerLoopScroll from '@shared/components/ADORN/TickerLoopScroll.vue'
import useScreenSize from '@shared/composables/screenSize'

const props = defineProps({
  planId: {
    type: String,
    required: true
  }
})
const { isTabletOrSmaller } = useScreenSize()
const imgSrc = process.env.VUE_APP_WEBSITE_IMAGES_URL

function getProductImage (planId, imgNum) {
  return `${imgSrc}sign-up/example-case-content/${planId}/PDP/item${imgNum}.jpg`
}
function getCarouselImage (planId, imgNum) {
  return `${imgSrc}sign-up/example-case-content/${planId}/Carousel/item${imgNum}.jpg`
}
function makeArray (num) {
  return Array.from({ length: num }, (_, i) => i)
}
function getFirstRowItems (num) {
  if (isTabletOrSmaller.value) {
    return makeArray(num).slice(0, 4)
  } else {
    return makeArray(num)
  }
}
function getSecondRowItems (num) {
  if (isTabletOrSmaller.value) {
    return makeArray(num).slice(4)
  } else {
    return []
  }
}
// 41DNE9XNWv / 7-item
// XzpND94jx1 / unlimited
// YRmV84VZrv / 4-item
const numItems = computed(() => {
  return props.planId === '41DNE9XNWv' ? [7, 8] : props.planId === 'XzpND94jx1' ? [6, 12] : props.planId === 'YRmV84VZrv' ? [4, 8] : 12
})
function scrollDuration (numItems) {
  return isTabletOrSmaller.value ? numItems * 1.5 : 100
}
</script>
<style scoped lang="scss">
section {
  height: 350px;
  @include media-tablet-or-smaller {
    max-width: 392px;
    margin: 0 auto;
  }
}
.product-image {
  width: 88px;
  height: auto;
  aspect-ratio: 88 / 117;
}
.carousel-image {
  width: 84px;
  height: auto;
}
.scroll-loop-container {
  max-width: 100%;
  width: 100%;
}
.first-row {
  @include media-tablet-or-smaller {
    grid-template-columns: repeat(4, 1fr);
  }
}
.first-row .product-image {
  @include media-tablet-or-smaller {
    width: 100%;
  }
}
.second-row {
  position: relative;
  top: -60px;
  margin-bottom: -60px;
}
</style>
