import { useSubscriptionStore } from '@/stores/subscribe.js'
import { capitalizeFirstLowercase } from '@/utils/stringParsing.js'
import { computed } from 'vue'
import { useStore } from 'vuex'

export default function usePrepay () {
  // Using the styleQuizNav store instead of creating a Pinia store
  // because it will be removed soon in favor of the signUpNavigation store.
  const store = useStore()
  const prepayGroup = computed(() => store.state.styleQuizNav.prepayGroup)

  const { prepayPlans } = useSubscriptionStore()

  const prepayPlanGroup = computed(() => {
    switch (prepayGroup.value) {
      case 'unlimited':
      case '7-item':
      case '4-item': {
        const name = capitalizeFirstLowercase(prepayGroup.value)
        return {
          name: prepayGroup.value,
          planCardNameOverride: `Armoire ${name === 'Unlimited' ? name : `<br> ${name} Plan`}`
        }
      }
      default:
        return {}
    }
  })
  const prepayHeroPlan = computed(() => prepayPlans(prepayGroup.value)?.slice(0, 1)[0] ?? {})
  const prepayPlansSorted = computed(() =>
    prepayPlans(prepayGroup.value).toSorted((a, b) => b.chargeInterval - a.chargeInterval) ?? []
  )

  return {
    prepayGroup,
    prepayPlans,
    prepayPlanGroup,
    prepayHeroPlan,
    prepayPlansSorted
  }
}
