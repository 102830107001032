<!-- Generic button with text and/or icon. -->
<template>
  <Component
    :is="type"
    v-bind="linkParams"
    ref="button"
    :class="['base-button', {
      [variant]: showText, // There's only one style when we're just showing an icon.
      'icon-only': !showText,
      'fixed-width': fixedWidth,
      'condensed': condensed}]"
    :disabled="disabled"
    @click.capture="onClick">
    <slot
      v-if="$slots.icon"
      name="icon"/>
    <div
      v-if="showText"
      class="text-nowrap">
      {{ text }}
    </div>
  </Component>
</template>

<script setup>
import { computed, ref } from 'vue'
import useLink from '@shared/composables/link.js'

const props = defineProps({
  to: {
    type: [String, Object],
    default: null
  },
  text: {
    type: String,
    default: null
  },
  variant: {
    type: String,
    default: 'primary',
    validator: value => [
      'primary',
      'secondary',
      'text-link',
      'text-link-light',
      'stylist',
      'filter-sort'
    ].includes(value)
  },
  disabled: {
    type: Boolean,
    default: null
  },
  fixedWidth: {
    type: Boolean,
    default: false
  },
  condensed: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits({
  click: (event) => event instanceof MouseEvent
})

const showText = computed(() => props.text?.length > 0)
const disabled = computed(() => props.disabled && props.variant !== 'stylist')

const link = useLink(() => props.to, { fallbackType: 'button' })
const { type, linkParams } = link

const button = ref(null)
function onClick (event) {
  if (disabled.value) {
    event.stopPropagation()
    return
  }
  button.value.blur?.() // Button, Anchor
  button.value.$el?.blur?.() // RouterLink
  emit('click', event)
}

defineExpose({ link })
</script>

<style lang="scss" scoped>
$icon-size: 28px;
$height: 44px;
$condensed-height: 32px;
.base-button {
  @include transition-base-all;

  padding: 12px 24px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: $height;
  flex-wrap: nowrap;
  column-gap: $spacing-xxs;
  outline: none;
  border: none;
  border-radius: $sequin-border-radius;

  font-family: $headings-font-family;
  font-weight: $font-weight-semibold;
  font-size: $small-text-font-size;
  line-height: $small-text-line-height;

  &.fixed-width {
    width: 300px;
  }

  &:has(svg) {
    padding: 8px 24px; // SVG is taller than text.
  }

  :slotted(svg) {
    width: $icon-size;
    height: $icon-size;
  }

  &.icon-only {
    border: 1px solid $orchid;
    color: $orchid;
    background-color: $white;
    padding: 7px;
    width: $height;

    &:hover {
      border-color: $plum;
      color: $plum;
      background-color: $origami;
    }
    &:focus:not(:hover), &:active {
      border-color: $plum;
      color: $armor;
      background-color: $origami;
    }
    &[disabled] {
      border-color: $ash;
      color: $ash;
      background-color: $origami;
    }
  }

  &, &.primary {
    background-color: $primary;
    color: $white;

    &:hover {
      background-color: $plum;
    }
    &:focus:not(:hover), &:active:not([disabled]) {
      box-shadow: 0 0 0 2px $ash;
      -webkit-box-shadow: 0 0 0 2px $ash;
    }
    &[disabled] {
      background-color: $origami;
      color: $ash;
      pointer-events: none;
    }
  }

  &.secondary {
    border: 1px solid $orchid;
    background-color: $white;
    color: $orchid;

    &:hover {
      border-color: $origami;
      background-color: $plum;
      color: $white;
    }
    &:focus:not(:hover) {
      background-color: $origami;
      color: $plum;
    }
    &[disabled] {
      border: 1px solid $ash;
      background-color: $origami;
      color: $ash;
    }
  }

  &.text-link {
    &, &:hover, &:focus:not(:hover) {
      background-color: unset;
      color: $orchid;
      text-decoration: underline;
      font-weight: $font-weight-normal;
    }
    &[disabled] {
      color: $ash;
    }
    &-light {
      background-color: transparent;
      color: $white;
    }
  }

  &.stylist {
    background-color: $teal;
    color: $white;
    width: 100%;

    &:hover, &:focus:not(:hover), &:active {
      background-color: $teal-dark;
    }
  }
  &.filter-sort {
   background-color: $plum;
   color: $white;
  }
  &.condensed {
    height: $condensed-height;
  }
}
</style>
