<template>
  <SignUpScreen
    heading="Tell Us Your Perfect Fit"
    :scrollable="true">
    <div class="sizing-screen-container text-center mt-sm">
      <div class="sizing-screen-inner-container d-flex flex-column row-gap-lgp">
        <div class="d-flex column-gap-lgp justify-content-center">
          <div class="d-flex column-gap-xs column-gap-md-lgp">
            <div
              class="text-start">
              <h6>Height*:</h6>
              <div class="d-flex gap-xs">
                <DropdownSingleSelect
                  v-if="styleProfile.heightFeetOptions.length > 0"
                  v-model="heightFeet"
                  class="input-shadow"
                  small
                  placeholder="Feet"
                  :dropdown-options="styleProfile.heightFeetOptions"
                  :initial-selection-index="getInitialSelectionIndex('heightFeet')"/>
                <DropdownSingleSelect
                  v-if="styleProfile.heightInchesOptions.length > 0"
                  v-model="heightInches"
                  class="input-shadow"
                  small
                  placeholder="Inches"
                  :dropdown-options="styleProfile.heightInchesOptions"
                  :initial-selection-index="getInitialSelectionIndex('heightInches')"/>
              </div>
            </div>
            <div
              class="text-start">
              <h6>Bra size*:</h6>
              <div class="d-flex gap-xs">
                <DropdownSingleSelect
                  v-if="styleProfile.braSizeBandOptions.length > 0"
                  v-model="braSizeBand"
                  class="input-shadow"
                  small
                  placeholder="Band"
                  :dropdown-options="styleProfile.braSizeBandOptions"
                  :initial-selection-index="getInitialSelectionIndex('braSizeBand')"/>
                <DropdownSingleSelect
                  v-if="styleProfile.braSizeCupOptions.length > 0"
                  v-model="braSizeCup"
                  class="input-shadow"
                  small
                  placeholder="Cup"
                  :dropdown-options="styleProfile.braSizeCupOptions"
                  :initial-selection-index="getInitialSelectionIndex('braSizeCup')"/>
              </div>
            </div>
          </div>
          <div
            v-if="!isMobile"
            class="text-start flex-grow-1">
            <h6>Your birthday*:</h6>
            <DatePicker
              v-model="birthDate"
              class="input-shadow"
              placeholder="Birthday*"/>
          </div>
        </div>
        <div
          v-if="isMobile"
          class="text-start">
          <h6>Tell us your birthday*:</h6>
          <p>We will send you a surprise on your big day!</p>
          <DatePicker
            v-model="birthDate"
            class="input-shadow"
            placeholder="Birthday*"/>
        </div>
        <div>
          <h6>Are you pregnant and interested in maternity styles?*</h6>
          <SingleSelectRow
            v-model="isMaternityCustomer"
            :class="{'maternity-item': !isMobile}"
            class="input-shadow"
            :options="maternityOptions"/>
        </div>

        <div v-if="visibleDueDate">
          <h6 class="caps">
            Congratulations!
          </h6>
          <h6 class="mt-2">
            When is your due date?*
          </h6>
          <DatePicker
            v-model="dueDate"
            class="mt-2 input-shadow"
            placeholder="Due Date*"/>
        </div>

        <div>
          <h6>What is your body shape?</h6>
          <HorizontalScroll
            v-if="styleProfile.bodyShapeOptions.length > 0"
            :full-bleed="true">
            <IconPill
              v-for="option in styleProfile.bodyShapeOptions"
              :key="option.value"
              v-model="bodyShape"
              class="shape-pill input-shadow"
              :label="`${toTitleCase(option.value || '?')}`"
              :description="option.text"
              type="radio"
              :value="option.value">
              <template #icon>
                <SvgBodyShapeHourglass
                  v-if="option.value === 'hourglass'"/>
                <SvgBodyShapeTriangle
                  v-else-if="option.value === 'triangle'"/>
                <SvgBodyShapeInvertedTriangle
                  v-else-if="option.value === 'inverted_triangle'"/>
                <SvgBodyShapeOval
                  v-else-if="option.value === 'oval'"/>
                <SvgBodyShapeRectangle
                  v-else-if="option.value === 'rectangle'"/>
              </template>
            </IconPill>
          </HorizontalScroll>
        </div>
      </div>
    </div>
  </SignUpScreen>
</template>

<script setup>
import SignUpScreen from '@/views/signUp/SignUpScreen.vue'
import DropdownSingleSelect from '@/components/global/sequin/DropdownSingleSelect.vue'
import SingleSelectRow from '@/components/global/sequin/SingleSelectRow.vue'
import DatePicker from '@/components/global/sequin/DatePicker.vue'
import IconPill from '@shared/components/ADORN/IconPill.vue'
import SvgBodyShapeHourglass from '@/components/global/svg/SvgBodyShapeHourGlass.vue'
import SvgBodyShapeInvertedTriangle from '@/components/global/svg/SvgBodyShapeInvertedTriangle.vue'
import SvgBodyShapeRectangle from '@/components/global/svg/SvgBodyShapeRectangle.vue'
import SvgBodyShapeTriangle from '@/components/global/svg/SvgBodyShapeTriangle.vue'
import SvgBodyShapeOval from '@/components/global/svg/SvgBodyShapeOval.vue'
import { useStyleProfileStore } from '@/stores/styleProfile.js'
import { computed } from 'vue'
import { toDayMonthYearString, toTitleCase } from '@/utils/stringParsing'
import HorizontalScroll from '@shared/components/ADORN/HorizontalScroll.vue'
import useScreenSize from '@shared/composables/screenSize'

const { isMobile } = useScreenSize()

const maternityOptions = [
  {
    text: 'Yes',
    value: 'true'
  },
  {
    text: 'No',
    value: 'false'
  }
]

const styleProfile = useStyleProfileStore()
const visibleDueDate = computed(() => styleProfile.isMaternityCustomer === 'true')

const braSizeBand = computed({
  get () {
    return styleProfile.braSizeBand
  },
  set (value) {
    styleProfile.SET_BRA_SIZE_BAND(value)
  }
})
const braSizeCup = computed({
  get () {
    return styleProfile.braSizeCup
  },
  set (value) {
    styleProfile.SET_BRA_SIZE_CUP(value)
  }
})
const heightFeet = computed({
  get () {
    return styleProfile.heightFeet
  },
  set (value) {
    styleProfile.SET_HEIGHT_FEET(value)
  }
})
const heightInches = computed({
  get () {
    return styleProfile.heightInches
  },
  set (value) {
    styleProfile.SET_HEIGHT_INCHES(value)
  }
})
const birthDate = computed({
  get () {
    return styleProfile.birthDate ? new Date(styleProfile.birthDate) : null
  },
  set (value) {
    const date = value ? toDayMonthYearString(value) : null
    styleProfile.SET_BIRTH_DATE(date)
  }
})
const isMaternityCustomer = computed({
  get () {
    return styleProfile.isMaternityCustomer
  },
  set (value) {
    styleProfile.SET_IS_MATERNITY_CUSTOMER(value)
    if (value === 'false') {
      styleProfile.SET_DUE_DATE(null)
    }
  }
})
const dueDate = computed({
  get () {
    return styleProfile.dueDate ? new Date(styleProfile.dueDate) : null
  },
  set (value) {
    const date = value ? toDayMonthYearString(value) : null
    styleProfile.SET_DUE_DATE(date)
  }
})
const bodyShape = computed({
  get () {
    return styleProfile.bodyShape ?? ''
  },
  set (value) {
    styleProfile.SET_BODY_SHAPE(value)
  }
})

const getInitialSelectionIndex = (type) => {
  const options = styleProfile[`${type}Options`]
  const selectedOption = styleProfile[`${type}`]

  if (selectedOption && options) {
    const index = options.findIndex(option => option.value === selectedOption)
    if (index > -1) return index
  }

  return null
}

</script>

<style lang="scss" scoped>
:deep(.calendar-button) {
  background-color: $plum;
  padding: 10px
}
:deep(.calendar-input) {
  padding: 7px 8px;
}
:deep(.single-select-row label) {
  padding: 8px !important;
  border-radius: $system-border-radius;
}
.dropdown-selector {
  width: 83px;
  min-width: unset;

  :deep(.dropdown-single-select) {
    padding: $spacing-xs $spacing-xxs $spacing-xs $spacing-xs;
  }

  :deep(.selected-option) {
    overflow: hidden;
  }

  :deep(svg) {
    margin-left: 0 !important;
  }
}
.sizing-screen-container {
  display: flex;
  justify-content: center;
}
.sizing-screen-inner-container {
  max-width: 856px;
  width: 100%;
}
.maternity-item {
  max-width: 415px;
  margin: 0 auto;
}
.shape-pill {
    min-width: 128px;
    width: 128px;
    align-self: stretch;
  :deep(.label) {
    height: 40px !important;
  }
  :deep(.description) {
    height: 60px;
  }
}
svg {
  width: 20px;
  height: 32px;
}
.input-shadow {
  box-shadow: 0px 1px 8px 0px rgba($armor, 0.10);
}
</style>
