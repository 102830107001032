import {
  getStylingInfo,
  getStylistClosets,
  postDeleteStylistCloset,
  postAddClientNote,
  postAddStyleColor,
  postDeleteClientNote,
  postRemoveStyleColor,
  postSaveStylingNote,
  postStylingRequest,
  postStylistCloset,
  postAddItemToStylistPackage,
  postRemoveItemFromStylistPackage,
  postFinishStylistPackage
} from '@shared/api/styling.js'
import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import logger from '@/logger'

export const useStylingStore = defineStore('styling', () => {
  // initialize vuex store
  const store = useStore()
  // state
  const stylingInfo = ref({})
  const clientNotes = ref([])
  const clientUsername = ref('')
  const closetId = ref(null)
  const closetItems = ref([])
  const closetStyleColorIds = ref([])
  const closetPublished = ref(false)
  const newClientNote = ref('')
  const stylistClosetInProgress = ref(false)
  const stylistId = ref(null)
  const stylistInitiated = ref(null)
  const stylingNote = ref('')
  const recentClosets = ref([])
  const errors = ref([])
  const stylistCuratedCaseInProgress = ref(false)

  // getters
  const rentalHistory = computed(() => stylingInfo.value?.rentedStyleColors)
  const pendingItems = computed(() => store.state.closet.pendingItems)
  const impersonator = computed(() => store.state.client.impersonator)

  const stylistCuratedCaseFull = computed(() => {
    return pendingItems.value.length === 0
  })

  const stylistPackageId = computed(() => {
    return stylingInfo.value.stylistCaseNote.package
  })

  // helper functions
  function clearCloset () {
    closetId.value = null
    closetItems.value = []
    closetPublished.value = false
    closetStyleColorIds.value = []
    stylistClosetInProgress.value = false
    stylistInitiated.value = null
    stylistClosetInProgress.value = false
    stylingNote.value = ''
  }
  function getStyleColorIdsFromClosetItems () {
    const styleColorIds = []
    closetItems.value.forEach(closetItem => {
      styleColorIds.push(closetItem.type.styleColor.id)
    })
    closetStyleColorIds.value = styleColorIds
  }
  function selectCloset (selectedClosetId) {
    const closet = recentClosets.value.find(closet => closet.pk === selectedClosetId)
    closetId.value = closet.pk
    stylingNote.value = closet.stylingNote
    closetItems.value = closet.items
    stylistInitiated.value = closet.stylistInitiated
    closetPublished.value = closet.showClient
    getStyleColorIdsFromClosetItems()
  }
  function createNewStylistCloset () {
    clearCloset()
    stylistClosetInProgress.value = true
  }
  function resumeStyling (selectedClosetId) {
    stylistClosetInProgress.value = true
    selectCloset(selectedClosetId)
  }
  function stopStyling () {
    clearCloset()
    fetchClosets({ username: clientUsername.value, stylistId: stylistId.value })
  }
  function closeError () {
    errors.value = []
  }
  function toggleStylistCuratedCaseInProgress () {
    stylistCuratedCaseInProgress.value = !stylistCuratedCaseInProgress.value
  }
  // api calls
  async function createStylingRequest (value) {
    stylistInitiated.value = value
    const data = {
      username: clientUsername.value,
      source: 'Impersonation',
      stylistInitiated: stylistInitiated.value,
      stylist_id: stylistId.value
    }
    const response = await postStylingRequest(data)
    closetId.value = response.data.closet.pk
    stylingNote.value = response.data.closet.stylingNote
  }
  async function fetchClosets (data) {
    clientUsername.value = data.username
    stylistId.value = data.stylistId
    const response = await getStylistClosets()
    recentClosets.value = response.data.closets || []
    if (response.data.closets) {
      if (response.data.closets.length === 1) {
        const closet = response.data.closets[0]
        selectCloset(closet.pk)
      }
    }
  }
  async function deleteStylistCloset (closetId) {
    const payload = {
      closetId: closetId
    }
    await postDeleteStylistCloset(payload)
    fetchClosets({ username: clientUsername.value, stylistId: stylistId.value })
  }
  async function fetchStylingInfo () {
    const response = await getStylingInfo()
    stylingInfo.value = response.data
    clientNotes.value = response.data.clientNotes
  }
  async function addClientNote (note) {
    const payload = {
      impersonator: impersonator.value,
      note: note
    }
    const response = await postAddClientNote(payload)
    clientNotes.value.push(response.data)
    newClientNote.value = ''
  }
  async function deleteClientNote (noteId) {
    const payload = {
      noteId: noteId
    }
    await postDeleteClientNote(payload)
    clientNotes.value = clientNotes.value.filter(note => note.id !== noteId)
  }
  async function saveStylistCloset () {
    const data = {
      closet_id: closetId.value,
      stylist_id: stylistId.value,
      styling_note: stylingNote.value
    }
    await postStylistCloset(data)
    clearCloset()
    fetchClosets({ username: clientUsername.value, stylistId: stylistId.value })
  }
  async function addStyleColor (styleColorId) {
    const payload = {
      closetId: closetId.value,
      styleColorId: styleColorId
    }
    const response = await postAddStyleColor(payload)
    if (response.data.item) {
      closetItems.value.push(response.data.item)
      // when an item is added AND the closet is published, the myStuff page should update to reflect the new item
      if (closetPublished.value) {
        store.dispatch('closet/refreshStylistSection')
      }
      getStyleColorIdsFromClosetItems()
      closeError()
    } else if (response.data.error) {
      errors.value = response.data.error
    }
  }
  async function removeStyleColor (styleColorId) {
    const closetItemToRemove = closetItems.value.find(closetItem => closetItem.type.styleColor.id === styleColorId)
    const payload = {
      closetId: closetId.value,
      itemId: closetItemToRemove.pk
    }
    const response = await postRemoveStyleColor(payload)
    if (response.data.success) {
      closetStyleColorIds.value = closetStyleColorIds.value.filter(id => id !== styleColorId)
      closetItems.value = closetItems.value.filter(closetItem => closetItem !== closetItemToRemove)
      // when an item is removed AND the closet is published, the myStuff page should update to reflect the change
      if (closetPublished.value) {
        store.dispatch('closet/refreshStylistSection')
      }
    }
  }
  async function saveStylingNote (newNote) {
    await postSaveStylingNote({ closet_id: closetId.value, styling_note: newNote })
    stylingNote.value = newNote
  }

  async function addStylistPackageItem (itemTypeId) {
    const payload = {
      itemTypeId: itemTypeId,
      clientUsername: clientUsername.value,
      source: 'stylist-curated',
      impersonator: impersonator.value
    }
    await postAddItemToStylistPackage(payload)
    await store.dispatch('closet/getPackageItems', null, { root: true })
  }

  async function removeStylistPackageItem (itemTypeId) {
    const payload = {
      itemTypeId: itemTypeId,
      clientUsername: clientUsername.value
    }
    await postRemoveItemFromStylistPackage(payload)
    await store.dispatch('closet/getPackageItems')
  }
  async function finishStylistCuratedCase () {
    // find the package, mark it styling complete
    if (stylistCuratedCaseFull.value) {
      const payload = {
        packageId: stylistPackageId.value
      }
      await postFinishStylistPackage(payload)
      await store.dispatch('closet/getPackageItems')
      await store.dispatch('closet/getActiveStylistCuratedPackage')
      stylistCuratedCaseInProgress.value = false
    } else {
      logger.info('NOT FULL! stylistCuratedCaseFull.value: ', stylistCuratedCaseFull.value)
    }
  }

  return {
    // state
    stylingInfo,
    clientNotes,
    clientUsername,
    closetId,
    closetItems,
    closetStyleColorIds,
    newClientNote,
    stylistClosetInProgress,
    stylistId,
    stylistInitiated,
    stylingNote,
    recentClosets,
    errors,
    stylistCuratedCaseInProgress,
    // getters
    rentalHistory,
    stylistCuratedCaseFull,
    // actions
    clearCloset,
    getStyleColorIdsFromClosetItems,
    selectCloset,
    createNewStylistCloset,
    resumeStyling,
    stopStyling,
    toggleStylistCuratedCaseInProgress,
    createStylingRequest,
    fetchClosets,
    deleteStylistCloset,
    fetchStylingInfo,
    addClientNote,
    deleteClientNote,
    saveStylistCloset,
    addStyleColor,
    removeStyleColor,
    closeError,
    saveStylingNote,
    addStylistPackageItem,
    removeStylistPackageItem,
    finishStylistCuratedCase
  }
})
