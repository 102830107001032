<!--
Pill component that can show any combination of an icon, label, and description,
and can act as a radio, checkbox, or just display a value.
-->
<template>
  <IconButton
    wrapper="label"
    :label="label"
    :description="description"
    :class="['icon-pill', type, { checked, disabled }]">
    <template
      v-if="$slots.icon"
      #icon>
      <slot name="icon"/>
    </template>
    <input
      v-if="type !== 'display'"
      v-model="_modelValue"
      :type="type"
      :value="value"
      :disabled="disabled"
      :checked="checked"
      :name="name">
  </IconButton>
</template>

<script setup>
import IconButton from '@shared/components/ADORN/IconButton.vue'
import { computed } from 'vue'

defineOptions({
  compatConfig: {
    COMPONENT_V_MODEL: false
  }
})

const props = defineProps({
  label: {
    type: String,
    default: null
  },
  description: {
    type: String,
    default: null
  },
  type: {
    type: String,
    default: 'display',
    validator: (value) => ['display', 'radio', 'checkbox'].includes(value)
  },
  modelValue: {
    type: [String, Number, Boolean, Array],
    required: true,
    validator: (value) => Array.isArray(value)
      ? value.every((v) => ['string', 'number', 'boolean'].includes(typeof v))
      : ['string', 'number', 'boolean'].includes(typeof value)
  },
  value: {
    type: [String, Number],
    required: true
  },
  disabled: {
    type: Boolean,
    default: false
  },
  name: {
    type: String,
    default: null
  }
})

const emit = defineEmits({
  'update:modelValue': (value) => ['string', 'number', 'boolean'].includes(typeof value) || Array.isArray(value)
})

const _modelValue = computed({
  get () { return props.modelValue },
  set (value) { emit('update:modelValue', value) }
})
const checked = computed(() =>
  !props.disabled &&
  props.type !== 'display' &&
  (Array.isArray(props.modelValue)
    ? props.modelValue.includes(props.value)
    : props.modelValue === props.value))
</script>

<style lang="scss" scoped>
.icon-layout {
  input {
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
  }

  &.display {
    cursor: default;
  }

  &.checked {
    background-color: $plum;
    color: $white;

    :slotted(*) {
      color: inherit;
    }
  }

  &.disabled:not(.display) {
    color: $ash;
    cursor: not-allowed;
    position: relative;
    overflow: hidden;
    border-color: $ash;
  }
}
</style>
