<template>
  <div class="wrapper">
    <back-button
      class="me-4 ms-3"
      with-border
      :back-default-route="{ name: 'closet-sections'}"/>
    <div class="w-100">
      <div class="d-flex detail-wrapper w-100">
        <div>
          <div class="d-flex">
            <closet-thumbnail-scroll>
              <div
                v-for="(image, index) in allImages"
                :key="image.url"
                class="pb-2 thumbnail"
                :class="{ 'selected': index === selectedImageIndex }"
                @mouseover="mouseoverImage(index)">
                <preview-image
                  :src="getImageUrl(allImages[index], 't')"
                  size="tiny"/>
              </div>
            </closet-thumbnail-scroll>
            <div class="d-flex justify-content-center">
              <div>
                <style-color-image-overlay
                  :message="sale && styleColor.saleEligible ? sale.displayName : ''"/>
                <transition
                  name="fade"
                  mode="out-in">
                  <div class="mx-3">
                    <style-color-preview-image
                      :key="selectedImageIndex"
                      action-button-orientation="horizontal"
                      size="x-large"
                      :src="allImages[selectedImageIndex].url"
                      :style-color="styleColor"
                      :style-color-source="styleColorSource"
                      :style-color-source-index="styleColorSourceIndex"
                      :disable-view-tracking="true"
                      @click="handleActionImageClick(selectedImageIndex)"/>
                    <div
                      v-if="currentCaption"
                      class="model-size small-text">
                      {{ currentCaption }}
                    </div>
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
        <div class="ms-4 w-100">
          <div class="d-flex justify-content-between">
            <div>
              <text-link
                class="pb-1"
                type="router-link"
                variant="secondary"
                :to="brandPageLink">
                {{ brand }}
              </text-link>
              <h4 class="me-4">
                {{ styleColor.style.name }}
              </h4>
            </div>
            <plain-button
              class="p-0"
              @click="onShareClick">
              <svg-share/>
            </plain-button>
          </div>

          <closet-detail-star-rating
            :style-color="styleColor"/>
          <closet-detail-bullets
            class="pt-3"
            :selected-item-type="selectedItemType"
            :style-color="styleColor"/>
          <div
            v-if="styleColor.numSizingRatings > 0 || styleColor.style.sizeGuide"
            class="mt-4 mb-2">
            <closet-sizing-rating
              :avg-sizing-rating="styleColor.avgSizingRating"
              :num-sizing-ratings="styleColor.numSizingRatings"
              :sizing-bias-recommendation="styleColor.sizingBiasRecommendation"
              :style-color="styleColor"/>
          </div>
          <div class="pt-3">
            <closet-detail-size-select
              v-if="!isDiscontinued && recommendedSizeLoaded && loaded"
              v-model="selectedItemType"
              :style-color="styleColor"
              @update-size-selection="updateSizeSelection"/>
            <closet-item-type-action-buttons
              v-if="recommendedSizeLoaded && loaded"
              class="mt-1"
              :selected-item-type="selectedItemType"
              :style-color="styleColor"
              @add-click="addToCase"
              @remove-click="removeFromCase"/>
            <div
              v-else-if="!recommendedSizeLoaded"
              class="w-100 d-flex justify-content-center">
              <svg-spinner
                width="36"/>
            </div>
            <div
              v-else>
              <sequin-button
                block
                type="router-link"
                :to="{
                  name: 'style-quiz-intro',
                  query: {
                    r: shareProfileId
                  }}">
                Sign up to rent this style
              </sequin-button>
              <div
                class="mt-3 sign-up small-text text-center">
                Access thousands of high-end styles for one flat monthly
                price! Learn <text-link
                  variant="tertiary"
                  @click="onHowItWorksClick">
                  how it works
                </text-link> or
                <text-link
                  type="router-link"
                  variant="tertiary"
                  :to="{ name: 'style-quiz-intro'}">
                  take our 3-minute style quiz
                </text-link>
                to get started.
              </div>
            </div>
          </div>
          <closet-detail-client-collections
            v-if="isAuthenticated"
            class="pt-3"
            :style-color="styleColor"/>
        </div>
      </div>
    </div>
    <collection-signup-footer
      ref="signupFooter"/>
  </div>
</template>

<script>
import { mapActions as mapVuexActions, mapGetters, mapState } from 'vuex'
import { mapActions, storeToRefs } from 'pinia'
import { getImageUrl } from '@/utils/stringParsing.js'
import { StyleColorMixin } from '../styleColorSet/StyleColorMixin'
import { vuexAccessors } from '../global/helpers/vuex'
import { useOverlaysStore } from '@/stores/overlays.js'
import { useStylingStore } from '@/stores/styling.js'
import CollectionSignupFooter from '../collections/CollectionSignupFooter.vue'
import StyleColorPreviewImage from '@shared/components/ADORN/StyleColorPreviewImage.vue'
import ClosetDetailBullets from './ClosetDetailBullets'
import ClosetDetailClientCollections from './ClosetDetailClientCollections.vue'
import ClosetItemTypeActionButtons from './ClosetItemTypeActionButtons'
import ClosetDetailSizeSelect from './ClosetDetailSizeSelect'
import BackButton from '../global/sequin/BackButton'
import PreviewImage from '@shared/components/ADORN/PreviewImage.vue'
import ClosetThumbnailScroll from './ClosetThumbnailScroll'
import SequinButton from '../global/sequin/SequinButton'
import TextLink from '../global/sequin/TextLink'
import PlainButton from '../global/sequin/PlainButton'
import StyleColorImageOverlay from '../styleColorSet/StyleColorImageOverlay'
import ClosetSizingRating from './ClosetSizingRating'
import ClosetDetailStarRating from './ClosetDetailStarRating'
import SvgShare from '@/components/global/svg/SvgShare.vue'
import SvgSpinner from '@/components/global/svg/SvgSpinner.vue'

export default {
  components: {
    PlainButton,
    CollectionSignupFooter,
    ClosetThumbnailScroll,
    ClosetDetailBullets,
    ClosetDetailClientCollections,
    ClosetItemTypeActionButtons,
    ClosetDetailSizeSelect,
    ClosetSizingRating,
    BackButton,
    StyleColorPreviewImage,
    PreviewImage,
    SequinButton,
    StyleColorImageOverlay,
    TextLink,
    ClosetDetailStarRating,
    SvgShare,
    SvgSpinner
  },
  mixins: [StyleColorMixin],
  props: {
    styleColor: {
      type: Object,
      required: true
    },
    styleColorSource: {
      type: Object,
      required: true
    },
    styleColorSourceIndex: {
      type: Number,
      default: () => null
    }
  },
  setup () {
    const { stylistCuratedCaseInProgress } = storeToRefs(useStylingStore())
    const { addStylistPackageItem, removeStylistPackageItem } = useStylingStore()
    return { stylistCuratedCaseInProgress, addStylistPackageItem, removeStylistPackageItem }
  },
  data () {
    return {
      selectedImageIndex: 0,
      selectedItemType: null
    }
  },
  computed: {
    ...mapGetters('closet', [
      'selectedAvailableItemTypes'
    ]),
    ...mapGetters('client', [
      'canSelectItems',
      'clientShareLinkParams'
    ]),
    ...mapState('client', [
      'loaded',
      'isAuthenticated'
    ]),
    ...mapState('closet', [
      'sale'
    ]),
    ...mapState('community', [
      'profileShareToken',
      'clientInfoById'
    ]),
    ...vuexAccessors('closet', [
      'photoAlbumIndex',
      'photoAlbumEntries'
    ]),
    shareProfileId () {
      return this.$route.query.shareProfileId
    },
    shareProfile () {
      return this.clientInfoById[this.shareProfileId]
    },
    currentCaption () {
      return this.allImages[this.selectedImageIndex].modelSizeCaption || this.styleColor.modelSizeCaption
    }
  },
  async mounted () {
    // add the share token / id to the URL automatically in case the
    // member does a copy and paste from the browser bar
    if (!this.$route.query.share && this.isAuthenticated) {
      if (!this.profileShareToken) {
        await this.getProfileShareToken()
      }
      this.$router.replace({ path: this.$route.path, query: this.clientShareLinkParams })
    }

    // Prefetch all medium sized images in parallel
    this.allImages.forEach(image => {
      const img = new Image()
      img.src = image.url
    })
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'openModal'
    ]),
    ...mapVuexActions('community', [
      'getProfileShareToken'
    ]),
    ...mapVuexActions('closet', [
      'deselect',
      'getPriceRange',
      'getClientSizingForStyleColor',
      'select'
    ]),
    getImageUrl,
    updateSizeSelection (selection) {
      this.selectedItemType = selection
    },
    onHowItWorksClick () {
      window.location = 'https://armoire.style/howitworks/'
    },
    async addToCase () {
      this.stylistCuratedCaseInProgress
        ? await this.addStylistPackageItem(this.selectedItemType.id)
        : this.select(this.selectedItemType)
    },
    async removeFromCase () {
      this.stylistCuratedCaseInProgress
        ? await this.removeStylistPackageItem(this.selectedItemType.id)
        : this.deselect(this.selectedItemType)
    },
    mouseoverImage (index) {
      this.selectedImageIndex = index
    },
    handleActionImageClick (index) {
      this.photoAlbumEntries = this.allImages.map(image => ({
        image: getImageUrl(image)
      }))
      this.photoAlbumIndex = index
      this.openModal({
        name: 'product-photo-detail',
        context: {
          shortName: `${this.styleColor.style.brand}${this.getShortName(this.styleColor)}`
        }
      })
    },
    onShareClick () {
      this.openModal({
        name: 'closet-share-actions',
        context: { styleColor: this.styleColor }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@shared/components/ADORN/PreviewImage.scss';

  :deep(.selected) .preview-image img {
    border: 1px solid black;
  }

  :deep(.preview-image.x-large) {
    // Preserve the "setWidth" size we use today.
    @include preview-image-size("x-large") using ($width, $height) {
      width: $height;
      height: auto;
    }
  }

  h5 {
    font-size: $small-text-font-size;
    line-height: $small-text-line-height;
  }

  .wrapper {
    display: flex;
    padding: 40px 40px 0 0;
  }

  .fade-enter-active, .fade-leave-active {
    transition-duration: 150ms;
  }

  .sign-up {
    color: $pewter;
  }

  :deep(.text-link) {
    padding: 0px;
  }

  .model-size {
    background-color: $origami;
    color: $plum;
    padding: 16px;
    text-align: center;
  }

  @media screen and (max-width: 900px) {
    .detail-wrapper {
      flex-direction: column;
    }
  }
</style>
