import { ref } from 'vue'
import useVuexModule from '@shared/composables/vuexModule.js'
import { defineStore } from 'pinia'

// TODO: Partial implementation (ENG-2859)
export const useSubscriptionStore = defineStore('subscription', () => {
  const vuex = useVuexModule('subscribe')

  const selectedPlanId = vuex.membershipPlanSelectedId
  const recommendedPlanId = ref('41DNE9XNWv') // TODO: ENG-4648

  return {
    ...vuex,
    selectedPlanId,
    recommendedPlanId
  }
})
