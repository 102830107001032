<template>
  <SignUpScreen :scrollable="true">
    <div class="plan-choice-screen mx-auto">
      <h2 class="text-center mt-xs mb-sm mb-lg-xl">
        Your Recommended Plan
      </h2>
      <div
        class="plan-cards d-flex gap-sm align-items-center mx-auto"
        :class="{ 'flex-column': isTabletOrSmaller }">
        <PlanChoiceCard
          v-if="recommendedPlanId && membershipPlans"
          :plan-id="recommendedPlanId"
          :selectable="selectedPlanId !== recommendedPlanId"
          :expanded="expandRecommendation"
          class="w-100"
          :class="`order-lg-${getPlanOrder(recommendedPlanId)}`"/>
        <p
          v-if="isTabletOrSmaller"
          class="semi-bold">
          Additional Plans
        </p>
        <PlanChoiceCard
          v-for="plan in additionalPlans"
          :key="plan.id"
          :plan-id="plan.id"
          :class="[`order-lg-${getPlanOrder(plan.id)}`, { 'w-100': isTabletOrSmaller }]"/>
      </div>
      <CalloutSection
        v-if="membershipPlans && !isTabletOrSmaller"
        :position="calloutPosition(selectedPlanId)">
        <template #default>
          <div
            v-for="plan in membershipPlans"
            :key="plan.id">
            <FadeTransition
              v-if="plan.id === selectedPlanId">
              <ExampleCaseSection
                :plan-id="plan.id"/>
            </FadeTransition>
          </div>
        </template>
      </CalloutSection>
    </div>
  </SignUpScreen>
</template>

<script setup>
import SignUpScreen from '@/views/signUp/SignUpScreen.vue'
import PlanChoiceCard from '@/components/signUp/PlanChoiceCard.vue'
import ExampleCaseSection from '@/components/signUp/ExampleCaseSection.vue'
import { computed, onMounted, ref } from 'vue'
import { useSubscriptionStore } from '@/stores/subscribe.js'
import { storeToRefs } from 'pinia'
import useScreenSize from '@shared/composables/screenSize.js'
import CalloutSection from '@shared/components/ADORN/CalloutSection.vue'
import FadeTransition from '@shared/components/ADORN/Transitions/FadeTransition.vue'

const { isTabletOrSmaller } = useScreenSize()

const subscriptionStore = useSubscriptionStore()
const { membershipPlans, selectedPlanId, recommendedPlanId } = storeToRefs(subscriptionStore)
const { fetchPlans, selectMembershipPlan } = subscriptionStore

const availablePlans = computed(() => membershipPlans.value?.filter(plan => !plan.prepayment && plan.basePrice > 0))
const additionalPlans = computed(() =>
  availablePlans.value?.filter(plan => plan.id !== recommendedPlanId.value)
    .toSorted((a, b) => {
      /*
      Unlimited selected: 7-item, 4-item.
      7-item selected: Unlimited, 4-item.
      4-item selected: 7-item, Unlimited.

      This just happens to currently map to sorting by nItems descending.
      */
      return b.nItems - a.nItems
    })
)

function getPlanOrder (planId) {
  if (planId === recommendedPlanId.value) {
    return 1 // Selected plan in the center (assuming 3 total)
  }
  const index = additionalPlans.value.findIndex(plan => plan.id === planId)
  if (index === 0) {
    return 0 // First additional plan on the left
  }
  return 2 // Remaining additional plans on the right
}
function calloutPosition (selectedPlanId) {
  return getPlanOrder(selectedPlanId) === 0
    ? 'start'
    : getPlanOrder(selectedPlanId) === 1
      ? 'center'
      : getPlanOrder(selectedPlanId) === 2
        ? 'end'
        : 'center'
}
const unlimitedPlanId = 'XzpND94jx1'
onMounted(async () => {
  if (!availablePlans.value) {
    await fetchPlans()
  }
  if (!selectedPlanId.value) {
    await selectMembershipPlan({ planId: unlimitedPlanId })
  }
})

const expandRecommendation = ref(false)
const expandTimeout = ref(null)
onMounted(() => {
  if (expandTimeout.value) {
    clearTimeout(expandTimeout.value)
  }
  expandTimeout.value = setTimeout(() => {
    expandRecommendation.value = true
  }, 1200)
})
</script>

<style lang="scss" scoped>
.plan-choice-screen {
  max-width: 1075px;
}
:deep(.plan-card) {
  @include media-tablet-or-smaller {
    max-width: 330px;
    margin: 0 auto;
  }
}
:deep(.base-accordion .header) {
  max-width: 330px;
  margin: 0 auto;
  width: 100%;
  padding: 0 $spacing-md;
}
:deep(.accordion-content) {
  margin: 0 calc($spacing-md * -1);
}
</style>
